<template>
  <div>
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Roles</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">Seguridad</li>
                  <li class="breadcrumb-item active">Roles</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal-form-roles-export"
                        v-if="$store.getters.can('admin.roles.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <div>
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="newItem = true"
                          v-if="$store.getters.can('admin.roles.create')"
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nombre</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in this.rolesPage"
                      :key="item.id"
                      :class="{ editing: item == editedItem }"
                      v-cloak
                    >
                      <td>
                        {{ item.id }}
                      </td>
                      <td>
                        <div class="view">
                          {{ item.name }}
                        </div>
                        <div class="edit">
                          <input type="text" v-model="item.name" required />
                        </div>
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <div class="view">
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              v-if="$store.getters.can('admin.roles.show')"
                              @click="editData(item)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                          </div>
                          <div class="edit">
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              v-if="$store.getters.can('admin.roles.show')"
                              @click="saveEditData(item)"
                            >
                              <i class="fas fa-save"></i>
                            </button>
                          </div>
                          <div class="view">
                            <button
                              type="button"
                              class="btn btn-sm btn-danger"
                              v-if="$store.getters.can('admin.roles.delete')"
                              @click="deleteRol(item.id)"
                            >
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                          <div class="edit">
                            <button
                              class="btn btn-sm btn-danger"
                              @click="editedItem = null"
                            >
                              <i class="fa fa-times"></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-show="newItem == true">
                      <th>ID</th>
                      <th>
                        <input type="text" v-model="roleNew.name" required />
                      </th>
                      <th>
                        <button
                          class="btn btn-sm btn-primary"
                          @click="saveRole"
                        >
                          <i class="fas fa-save"></i>
                        </button>
                        <button
                          class="btn btn-sm btn-danger"
                          @click="newItem = false"
                        >
                          <i class="fa fa-times"></i>
                        </button>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
      <RolesExport ref="RolesExport" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
//import serverConfig from "./../../../../services/config";
import RolesExport from "./RolesExport";

export default {
  name: "RolesIndex",
  components: {
    RolesExport,
  },
  data() {
    return {
      rolesPage: [],
      role: "",
      newItem: false,
      roleNew: {
        name: "",
        guard_name: "",
      },
      editMode: false,
      editedItem: null,
    };
  },
  beforeMount() {
    this.actGetRoles().then(() => {
      this.rolesPage = this.roles;
    });
  },
  computed: {
    ...mapState("modSeguridad", ["roles"]),
  },
  methods: {
    ...mapActions("modSeguridad", [
      "actGetRoles",
      "actSaveRoles",
      "actEditRoles",
      "actDeleteRoles",
    ]),

    saveRole() {
      //se asigna por defecto el guard web
      this.roleNew.guard_name = "web";
      this.actSaveRoles(this.roleNew).then(() => {
        this.$swal({
          icon: "success",
          title: "El rol se creo con exito",
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });

        this.newItem = false;
        this.rolesPage = [];
        this.actGetRoles().then(() => {
          this.rolesPage = this.roles;
        });
      });
    },

    editData(item) {
      this.beforEditCache = item;
      this.editedItem = item;
    },

    saveEditData(item) {
      let data = [];
      data[0] = item.id;
      data[1] = this.editedItem.name;
      data[2] = item.guard_name;

      this.actEditRoles(data).then(() => {
        this.$swal({
          icon: "success",
          title: "El rol quedó actualizado",
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });

        this.editedItem = null;
        this.rolesPage = [];
        this.actGetRoles().then(() => {
          this.rolesPage = this.roles;
        });
      });
    },

    deleteRol(id) {
      this.$swal({
        title: "Esta seguro de eliminar este Item?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          this.actDeleteRoles(id).then(() => {
            this.$swal({
              icon: "success",
              title: "El rol quedó eliminado",
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });

            this.rolesPage = [];
            this.actGetRoles().then(() => {
              this.rolesPage = this.roles;
            });
          });
        }
      });
    },
  },
};
</script>

<!-- <style lang="scss">
[v-cloak] {
  display: none;
}
.edit {
  display: none;
}
.editing .edit {
  display: block;
}
.editing .view {
  display: none;
}
</style>
 -->